<template>
 <div class="page-container">
	 <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
	 <div class="page-desc">
		折旧方案设置	
	 </div>  
	 <div style="margin-bottom:10px;">
	      <el-button size="small" type="" @click="handleAdd()">添加</el-button>
	  </div> 
	  <el-table
	     :data="tableData" 
	     border
	     style="width:100%">
	      <el-table-column
	         prop="name"
	         label="折旧方案名称">
	     </el-table-column>
	     <el-table-column 
	         label="折旧方法">
			  <template slot-scope="scope">
			  <span v-if="scope.row.method_type == 1">平均年限法</span>
			 </template>
	     </el-table-column>
	     <el-table-column
	         prop="e_salvage_rate"
	         label="预计残值率(%)">
	     </el-table-column>
		<el-table-column
	         label="首次折旧时间">
			 <template slot-scope="scope">
			  <span v-if="scope.row.first_dprn_time == 1">入库时间当月</span>
			   <span v-else>入库时间次月</span>
			 </template>
	     </el-table-column>
		<el-table-column
	         prop="cate_name"
	         label="适用分类">
	     </el-table-column>
		 <el-table-column
		      label="适用购置方式">
			  <template slot-scope="scope">
				  <span v-if="scope.row.buy_srouce < 1">采购</span>
				   <span v-else>租赁</span>
			  </template>
		  </el-table-column>
		  <el-table-column
		       prop="remark"
		       label="备注">
		   </el-table-column>
	     <el-table-column
	         label="操作">
	         <template slot-scope="scope">
	             <el-button  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
	             <el-button  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
	         </template>
	     </el-table-column>
	  </el-table>
	  <pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>
	<AddVue ref="childDialog"></AddVue>
 </div>
</template>

<script>
import AddVue from './Add.vue'
export default {
	 inject:['reload'],
	components:{
	    AddVue,
	},
    data() {
        return {
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		question(){
			this.$refs.docDialog.getDoc(9)
		},
		getData(){  
		    this.$api.post('AssetDprn/index',{
		        page:this.currentPage,
		        limit:this.pagesize,
		    },res=>{
		        if(res.code == 200){
		             this.tableData =  res.data.list
		             this.total =  res.data.count
		        }else{
		            this.$message.error(res.msg)
		        }
		    },fail=>{
				this.$message.error(fail.msg)
			})
		},
		handleAdd(id=0){
			this.$refs.childDialog.handleAdd(id)
		},
		handleDel(row){
		      this.$alert("确定删除该折旧方案吗？", "删除提示", {
		         showConfirmButton: true,
		         showCancelButton: true,
		         callback: action => {
		             if (action == "confirm") {
		                 this.$api.delete("AssetDprn/del", { "id": row.id }, res => {
		                     if (res.code == 200) {
		                         this.$message.success(res.msg);
		                         this.reload();
		                     }
		                 });
		             }
		             else if (action == "cancel") {
		                 this.reload();
		             }
		         }
		     });
		},
		  getCurrPage(val){   //获取当前页
		     this.currentPage =  val
		     this.getData()
		 },
		 getPageSize(val){   //获取每页条数
		     this.pagesize =  val
		     this.getData()
		 },
		
    },
}
</script>
<style scoped>

</style>