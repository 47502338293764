<template>
<div>
	<el-dialog title="折旧方案设置" :visible.sync="dialogAddVisible" :close-on-click-modal="false"  width="28%">
	     <el-form class="dprn-form" ref='form' :model="form" prop="" label-width="110px" >
	        <el-form-item label="折旧方案简称" required>
	            <el-input  v-model="form.name"></el-input>
	        </el-form-item>
	         <el-form-item label="折旧方法" required>
	            <el-select v-model="form.method_type"  placeholder="请选择" disabled style="width: 100%;">
	            	<el-option :value="1" label="平均年限法"></el-option>
	            </el-select>
	        </el-form-item>
			<el-form-item label="预计残值率" required>
				  <el-input-number  v-model="form.e_salvage_rate" controls-position="right" :precision="2"  :min="1" :max="100"></el-input-number>
				 <span style="margin-left: 10px;font-size: 18px;">%</span>
			</el-form-item>
			
			<el-form-item label="首次折旧时间" required>
			     <el-select v-model="form.first_dprn_time" clearable placeholder="请选择"  style="width: 100%;">
			     	<el-option :value="1" label="入库时间当月"></el-option>
					<el-option :value="2" label="入库时间次月"></el-option>
			     </el-select>
			</el-form-item>
			<el-form-item label="适用分类" required>
				 <el-select multiple  ref="treeSelect" v-model="selectedCate" placeholder="请选择" clearable style="width: 100%;">
				 	<el-option value="" label=""></el-option>
				 	<el-tree node-key="id"
						ref="tree"
						 default-expand-all
						show-checkbox
						@check-change="handleCheckChange"
						 :data="cateList" 
						 :props="treePorps" 
						 highlight-current > </el-tree>
				 </el-select>
			</el-form-item>
			<el-form-item label="适用购置方式" required>
			     <el-select v-model="form.buy_source" clearable placeholder="请选择" style="width: 100%;">
			     	<el-option :value="0" label="采购"></el-option>
					<el-option :value="1" label="租赁"></el-option>
			     </el-select>
			</el-form-item>
			<el-form-item label="备注" prop="">
			    <el-input type="textarea" rows="5" v-model="form.remark"></el-input>
			</el-form-item>
	     </el-form>
	     <div slot="footer" class="dialog-footer">
	        <el-button @click="dialogAddVisible = false">取 消</el-button>
	        <el-button type="primary" @click="confirm()">确 定</el-button>
	    </div>
	</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			 dialogAddVisible:false,
			 cateList:[],
			 selectedCate:[],
			 form:{
			     id:'',
				 name:'',
				 method_type:1,
				 first_dprn_time:'',
			     e_salvage_rate:'',
			     cate_id:[],
			     buy_source:'',
			     remark:'',
				 parent_name:''
			 },
			
			 treePorps: {
			 	label: "name"
			 }
        }
    },
    created() {

    },
    mounted() {

    },
    methods:{
		handleAdd(id=0){
			if(id < 1){
				this.$api.get('AssetDprn/add',null,res=>{
				    if(res.code == 200){
						this.cateList = res.data.cate_list
						this.dialogAddVisible = true;
						// this.$nextTick(()=>{
						// 	this.$refs.tree.setCheckedKeys([7,9]);
						// }) 
				    }
				})
			}else{
				this.$api.get('AssetDprn/edit',{id:id},res=>{
				    if(res.code == 200){
						this.cateList = res.data.cate_list
				        this.form  = res.data.info
						var checkedKeys = res.data.checked_keys
						console.log(checkedKeys)
						this.$nextTick(()=>{
							this.$refs.tree.setCheckedKeys(checkedKeys);
						}) 
				        this.dialogAddVisible = true;
						
				    }
				})
			}
		  
		},
		confirm(){
			let url = 'AssetDprn/add'
			if (this.form.id) {
				url = 'AssetDprn/edit'
			}
		    this.$api.post(url,this.form,res=>{
		        if(res.code == 200){
		            this.$message.success(res.msg);
		            this.dialogAddVisible = false;
		            this.$refs["form"].resetFields();
		            this.reload();
		        }else{
		            this.$message.error(res.msg)
		        }
		    })
		},
		
		handleSelect(val){
			console.log('select:',val)
			this.form.pid =  val
		},
		handleCheckChange(val){
			// this.selectedCate =  this.$refs.tree.getCheckedNodes()
			this.selectedCate = [];
			var nodes =  this.$refs.tree.getCheckedNodes()
			nodes.map(item=>{this.selectedCate.push(item.name)})
			
			this.form.cate_id =  this.$refs.tree.getCheckedKeys()
		}
    },
}
</script>
<style  lang="scss">
	// .dprn-form{
	// 	.el-input__inner,.el-textarea__inner{
	// 		width: 350px !important;
	// 	}
	// }
</style>